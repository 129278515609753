<template>


  <v-layout>
    <!-- <v-system-bar color="deep-purple darken-3"></v-system-bar> -->

    <v-app-bar color="appBarColor" density="confortable" prominent  >
      <v-app-bar-nav-icon v-if="showIFUAppBar" variant="text" @click.stop="drawer = !drawer" color="appBarIconColor"></v-app-bar-nav-icon>

      <v-toolbar-title v-if="showIFUAppBar">{{ $t('LOOP Patient Guide') }}</v-toolbar-title>

      <v-btn v-if="showIFUAppBar" @click="goBack" class="elevation-8 mr-2" icon="mdi-arrow-left-top-bold" color="appBarIconColor"></v-btn>
  

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn density="comfortable" class="elevation-1"  color="appBarIconColor" icon="mdi-web" v-bind="props">
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale"
            @click="$i18n.locale = locale">
            <v-list-item-title>{{
        $i18n.messages[locale]['Language']
      }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        

    </v-app-bar>

    <v-navigation-drawer v-model="drawer" location="left" 
    temporary color="appBarColor" class="pt-1" v-if="showIFUNavDrawer">

      <v-list>
        <router-link to="/" class="router-link">
          <v-list-item  :title="$t('Home Page')">
            <template v-slot:prepend>
              <v-icon icon="mdi-home" color="navigationDrawerIconColor" size="large"></v-icon>
            </template>
          </v-list-item>
        </router-link>

        <router-link v-for="(menu, i) in menus" :key="i" :to="menu.route" class="router-link">
          <v-list-item :prepend-icon="menu.icon" :title="$t(menu.title)">
            <template v-slot:prepend>
              <v-icon color="navigationDrawerIconColor" size="30"></v-icon>
            </template>
          </v-list-item>
        </router-link>

      </v-list>
      
      
  

    </v-navigation-drawer>

    <v-main style="height: auto;">
      <router-view></router-view>
    </v-main>
   
  </v-layout>



</template>

<script>

import { store } from './store.js'
import './global.css';

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    menus: store.menus,

    showIFUAppBar: true,
    showIFUNavDrawer: true

  }),
  methods: {
    goBack() {
      window.history.back();
    },
    updateVisibility(route) {
      // Determine visibility based on route
      this.showIFUAppBar = !route.meta.hideIFUAppBar;
      this.showIFUNavDrawer = !route.meta.hideIFUNavDrawer;
    }
  },
  created() {
    // Extract client language when the component is created
    this.userLanguage = navigator.language || navigator.languages[0];
    this.$i18n.locale = this.userLanguage.split('-')[0];

    this.$router.beforeEach((to, from, next) => {
      // Update visibility based on route
      this.updateVisibility(to);
      next();
    });

  }
}
</script>
<style>
.list__tile--active .list__tile__action,
.list__tile--active .list__tile__action .icon {
  color: inherit;
}
</style>
