// eslint-disable-next-line no-unused-vars
import { reactive } from 'vue'




export const store = reactive({
  menus: [
    { title: 'What is NPWT?', icon: "mdi-lightbulb-multiple", route: '/whatIsNpwt' },
    { title: 'Healthcare Provider', icon: "mdi-doctor", route: '/healthcareProvider' },
    { title: 'System Components', icon: "mdi-rhombus-split", route: '/systemComponents' },
    { title: 'Quick Device Reference', icon: "mdi-gesture-tap-button", route: '/deviceReference' },
    { title: 'Important Tips', icon: "mdi-thumbs-up-down", route: '/importantTips' },
    { title: 'Daily Usage', icon: "mdi-run-fast", route: '/dailyUsage' },
    { title: 'Alert Management', icon: "mdi-alert", route: '/alertManagement' }
  ]
})
