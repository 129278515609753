//import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'

//import MainMenuView from '../views/MainMenuView.vue'
import MenuPageView from '../views/MenuPageView.vue'
import WhatIsNpwtView2 from '../views/WhatIsNpwtView2.vue'
import HealthcareProvider from '../views/HealthcareProvider.vue'
import ImportantTips from '../views/ImportantTips.vue'
import AlertManagement from '../views/AlertManagement.vue'
import DeviceReference from '../views/DeviceReference.vue'
import DailyUsage from '../views/DailyUsage.vue'
import SystemComponents from '../views/SystemComponents.vue'

//DAILY USAGE VIEWS
import RechargingDevice from '../views/dailyuses/RechargingDevice.vue'



const routes = [
  {
    path: '/',
    name: 'MenuPageView',
    component: MenuPageView
  },
  {
    path: '/whatIsNpwt',
    name: 'whatIsNpwt',
    //component: () => import(/* webpackChunkName: "about" */ '../views/WhatIsNpwtView2.vue')
    component: WhatIsNpwtView2
  },
  {
    path: '/healthcareProvider',
    name: 'healthcareProvider',
    component: HealthcareProvider
  },
  {
    path: '/importantTips',
    name: 'importantTips',
    component: ImportantTips
  },
  {
    path: '/alertManagement',
    name: 'alertManagement',
    component: AlertManagement
  },
  {
    path: '/deviceReference',
    name: 'deviceReference',
    component: DeviceReference
  },
  {
    path: '/dailyUsage',
    name: 'dailyUsage',
    component: DailyUsage
  },
  {
    path: '/systemComponents',
    name: 'systemComponents',
    component: SystemComponents
  },
//DAILY USAGE PAGES
  {
    path: '/rechargingDevice',
    name: 'rechargingDevice',
    component: RechargingDevice
  },
  //END OF DAILY USAGE PAGES

//ALARM PAGES
  {
    path: '/ALK',
    name: 'leakageAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/leakageAlert.vue'),
  },
  {
    path: '/ABL',
    name: 'blockageAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/blockageAlert.vue'),
  },
  {
    path: '/ACF',
    name: 'canisterFullAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/canisterFullAlert.vue'),
  },
  {
    path: '/ALB',
    name: 'lowBatteryAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/lowBatteryAlert.vue'),
  },
  {
    path: '/ACB',
    name: 'criticalBatteryAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/lowBatteryAlert.vue'),
  },
  {
    path: '/ABN',
    name: 'bleedingAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/bleedingAlert.vue'),
  },
  {
    path: '/ADC',
    name: 'dressingChangeAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/dressingChangeAlert.vue'),
  },
  {
    path: '/ATT',
    name: 'treatmentTimeAlert',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/alarms/treatmentTimeAlert.vue'),
  },
  //END OF ALARM PAGES
  
  
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "termsOfUse" */ '../views/NotFound.vue'),
  },

  {
    //path: '/about',
    //name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  mode: 'history',
  history: createWebHashHistory(),
  //history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
