<template>

  <PageTitleComponent :pageTitle="$t('System Components')"></PageTitleComponent>



  <v-img class="bg-grey-lighten-2" :src="require('@/assets/images/systemcomponents/system_components.png')" cover></v-img>

  <v-expansion-panels class="mt-2 mx-2">
    <v-expansion-panel class="mb-3" v-for="(component, i) in components" :key="i">
      <v-expansion-panel-title class="elevated-2" color="expansionPanelTitleColor">
        <v-avatar class="mr-3" rounded="0" size="30" variant="text">
          <v-img :src="component.icon" aspect-ratio="1/1"></v-img>
        </v-avatar>
        {{ (i+1) + '. ' + $t(component.title) }}
      </v-expansion-panel-title>
      <v-expansion-panel-text class="text-justify">
        {{ $t(component.text) }}
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

</template>

<script>

import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';


export default defineComponent({
  name: 'SystemComponents',

  data() {
    return {
      components: [
        { title: 'SystemComponentsTitle1', icon: require("@/assets/images/systemcomponents/loop_device.png"), text: 'SystemComponentsText1' },
        { title: 'SystemComponentsTitle2', icon: require("@/assets/images/systemcomponents/canister.png"), text: 'SystemComponentsText2' },
        { title: 'SystemComponentsTitle3', icon: require("@/assets/images/systemcomponents/wound_dressing.png"), text: 'SystemComponentsText3' },
        { title: 'SystemComponentsTitle4', icon: require("@/assets/images/systemcomponents/adaptor.png"), text: 'SystemComponentsText4' },
        { title: 'SystemComponentsTitle5', icon: require("@/assets/images/systemcomponents/carry_strap.png"), text: 'SystemComponentsText5' },
        { title: 'SystemComponentsTitle6', icon: require("@/assets/images/systemcomponents/loop_eye.png"), text: 'SystemComponentsText6' },
        { title: 'SystemComponentsTitle7', icon: require("@/assets/images/systemcomponents/y_connector.png"), text: 'SystemComponentsText7' }
      ]
    }
  },
  components: {
    PageTitleComponent
  },
  computed: {

  },
});
</script>
