<template>
    <div class="mt-3" align="center">
      
      <v-container>


        <v-row align="center" justify="center" class="d-flex" style="flex-direction:row">
         
            <v-col v-for="(menu, i) in menus" :key="i" cols="6" class="flex-grow-1">
            <v-card align="center" color="mainMenuBtnColor" variant="elevated" class="mx-auto d-flex align-center justify-center" hover :href="menu.route"
              height="100">
              <v-card-item>
                <router-link  :to="menu.route" class="router-link">
                <div>
                  <v-icon :icon="menu.icon" size="40" color="mainMenuBtnIconColor"></v-icon>
                  <div class="text-h8 ma-1">{{ $t(menu.title) }}</div>
                </div>
              </router-link>
              </v-card-item>
            </v-card>
          </v-col>
          

          
        </v-row>
      </v-container>
    </div>
  </template>
  


  <script>

  import { defineComponent } from 'vue';
  import { store } from '../store.js'
  
  export default defineComponent({
    name: 'MenuPageView',
    
   data() {
      return {
        menus : store.menus    
      }
    },
  components: {
  
    },
  });
  </script>