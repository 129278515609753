<template>


    <PageTitleComponent  :pageTitle="$t('Alert Management')"></PageTitleComponent>

    <v-card class="mx-3 my-3" variant="elevated">

        <v-card-text class="text-justify">
            {{ $t('AlertManagementTopParagraph') }}
        </v-card-text>
    </v-card>
    <v-card class="my-2" variant="elevated">
        <v-card-text>
            <router-link  :to="alert.route" class="router-link" v-for="(alert, i) in alerts"
                :key="i">
            <v-banner class="elevation-5 mb-3" bg-color="expansionPanelTitleColor" lines="one" :icon="alert.icon" color="listIconColor" >            
                <v-banner-text>
                    {{ $t(alert.title) }}
                </v-banner-text>
                <template v-slot:actions>
                    <v-btn size="large">
                    <v-icon icon="mdi-arrow-right-bold"></v-icon>
                </v-btn>
                </template>
            </v-banner>
              </router-link>
        </v-card-text>
    </v-card>


</template>

<script>

import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';

export default defineComponent({
    name: 'AlertManagement',

    data() {
        return {
            alerts: [
                { title: 'Leakage Alert', icon: "mdi-pipe-leak", route: "/ALK" },
                { title: 'Blockage Alert', icon: "mdi-sync-off", route: "/ABL" },
                { title: 'Low Battery Alert', icon: "mdi-battery-low", route: "/ALB" },
                { title: 'Canister Full Alert', icon: "mdi-delete-restore", route: "/ACF" },
                { title: 'Dressing Change Alert', icon: "mdi-waves-arrow-up", route: "/ADC" },
                { title: 'Treatment Time Alert', icon: "mdi-timer-alert", route: "/ATT" },
                { title: 'Bleeding Alert', icon: "mdi-water-circle", route: "/ABN" }
            ]
        }
    },
    components: {
        PageTitleComponent,
    },
});
</script>