<template>

  <PageTitleComponent :pageTitle="$t('Quick Device Reference')"></PageTitleComponent>



  <v-img class="bg-grey-lighten-2" :src="require('@/assets/images/deviceelements/device_reference.png')" cover></v-img>

  <v-container>
    <v-row dense>
      <v-col cols="12" v-for="(element, i) in elements" :key="i">
        <v-card class="mb-3" color="expansionPanelTitleColor">
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h6">
                {{ (i+1) + '. ' +  $t(element.title) }}
              </v-card-title>

              <v-card-text class="text-justify"> {{ $t(element.text) }}</v-card-text>
            </div>

          </div>
        </v-card>
      </v-col>

    </v-row>
  </v-container>

</template>

<script>

import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';


export default defineComponent({
  name: 'DeviceReference',

  data() {
    return {
      elements: [
        { title: 'DeviceReferenceTitle1', icon: require("@/assets/images/deviceelements/onoff_button.png"), text: 'DeviceReferenceText1' },
        { title: 'DeviceReferenceTitle2', icon: require("@/assets/images/deviceelements/charger_socket.png"), text: 'DeviceReferenceText2' },
        { title: 'DeviceReferenceTitle3', icon: require("@/assets/images/deviceelements/canister_lock.png"), text: 'DeviceReferenceText3' },
        { title: 'DeviceReferenceTitle4', icon: require("@/assets/images/deviceelements/strap_grips.png"), text: 'DeviceReferenceText4' },
        { title: 'DeviceReferenceTitle5', icon: require("@/assets/images/deviceelements/touch_screen.png"), text: 'DeviceReferenceText5' },
        { title: 'DeviceReferenceTitle6', icon: require("@/assets/images/deviceelements/loop_eye.png"), text: 'DeviceReferenceText6' }
      ]
    }
  },
  components: {
    PageTitleComponent
  },
  computed: {
    
  },
});
</script>
