<template>

  <PageTitleComponent :pageTitle="$t('What is NPWT?')"></PageTitleComponent>

  <v-card class="mx-3 my-3" variant="elevated" v-for="(panel, i) in panels" :key="i">
    <v-card-title>
      {{ $t(panel.title) }}
    </v-card-title>
    <v-card-text class="text-justify">
      {{ $t(panel.text) }}
    </v-card-text>
  </v-card>

  <v-card class="mx-3 my-3" variant="elevated">
    <v-card-title>
      {{ $t('WhatIsNpwtPanelTitle5') }}
    </v-card-title>
    <v-card-text>
      <v-card class="mx-3 my-3" v-for="(benefit, i) in benefits" :key="i" color="expansionPanelTitleColor">
        <template v-slot:append>
          <v-icon :icon="benefit.icon" color="listIconColor" size="large"></v-icon>
        </template>
        <template v-slot:title>
          {{ $t(benefit.title) }}
        </template>
        <v-card-text>
          {{ $t(benefit.text) }}
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>

import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';

export default defineComponent({
  name: 'WhatIsNpwtView2',

  data() {
    return {
      panels: [
        { title: 'WhatIsNpwtPanelTitle1', icon: "mdi-lightbulb-multiple", text: 'WhatIsNpwtPanelText1' },
        { title: 'WhatIsNpwtPanelTitle2', icon: "mdi-lightbulb-multiple", text: 'WhatIsNpwtPanelText2' },
        { title: 'WhatIsNpwtPanelTitle3', icon: "mdi-lightbulb-multiple", text: 'WhatIsNpwtPanelText3' },
        { title: 'WhatIsNpwtPanelTitle4', icon: "mdi-lightbulb-multiple", text: 'WhatIsNpwtPanelText4' }
      ],
      benefits: [
        { title: 'NPWTBenefitsTitle1', icon: "mdi-shield-account", text: 'NPWTBenefitsText1' },
        { title: 'NPWTBenefitsTitle2', icon: "mdi-cup-outline", text: 'NPWTBenefitsText2' },
        { title: 'NPWTBenefitsTitle3', icon: "mdi-heart-plus", text: 'NPWTBenefitsText3' },
        { title: 'NPWTBenefitsTitle4', icon: "mdi-rotate-orbit", text: 'NPWTBenefitsText4' },
        { title: 'NPWTBenefitsTitle5', icon: "mdi-scent-off", text: 'NPWTBenefitsText5' },
        { title: 'NPWTBenefitsTitle6', icon: "mdi-emoticon", text: 'NPWTBenefitsText6' }

      ]
    }
  },
  components: {
    PageTitleComponent
  }
});
</script>
