<template>
   
    <v-sheet class="d-flex align-center justify-center flex-wrap text-center mb-4 py-2" elevation="4"
    color="pageTitleBackgroundColor">
    <div class="text-h6" align="center">
      {{ pageTitle }}
    </div>
  </v-sheet>
  </template>
  
  <script>
  export default {
    props: {
      pageTitle: {
        type: String,
        required: true
      }
    }
  };
  </script>