// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'



const myCustomLightTheme = {
  dark: false,
  colors: {
    appBarColor : '#5C6BC0',
    appBarIconColor : '#FFFFFF',
    navigationDrawerColor: '#1A237E',
    navigationDrawerIconColor: '#FFFFFF',

    mainMenuBtnColor: '#3949AB',
    mainMenuBtnIconColor: '#FFFFFF',

    pageTitleBackgroundColor: '#3949AB',

    listIconColor: '#3949AB',
    expansionPanelTitleColor: '#C5CAE9',

    background: '#CAD6FF',
    primary: '#42A5F5',
    'primary-darken-1': '#1F5592',
    secondary: '#3F51B5',
    'secondary-darken-1': '#F57C00',
    error: '#B00020',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
  },
  variables: {
    'border-color': '#000000',
    'border-opacity': 0.12,
    'high-emphasis-opacity': 0.87,
    'medium-emphasis-opacity': 0.60,
    'disabled-opacity': 0.38,
    'idle-opacity': 0.04,
    'hover-opacity': 0.04,
    'focus-opacity': 0.12,
    'selected-opacity': 0.08,
    'activated-opacity': 0.12,
    'pressed-opacity': 0.12,
    'dragged-opacity': 0.08,
    'theme-kbd': '#212529',
    'theme-on-kbd': '#FFFFFF',
    'theme-code': '#F5F5F5',
    'theme-on-code': '#000000',
  }
}

const menuList = 'MERHABA'

export default createVuetify({ 
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  theme: {
    defaultTheme: 'myCustomLightTheme',
    themes: {
      myCustomLightTheme,
    },
  },
  menuList : menuList,
})
