<template>

    <PageTitleComponent :pageTitle="$t('Important Tips')"></PageTitleComponent>


    <v-card class="mx-3 my-3" variant="elevated">

        <v-card-text class="text-justify">
            {{ $t('ImportantTipsTopParagraph') }}
        </v-card-text>
    </v-card>
    <v-card class="my-2" variant="elevated" >
        <v-card-text>
            <v-banner bg-color="expansionPanelTitleColor" class="my-2 rounded-lg" lines="three" :icon="tip.icon" :color="tip.color" v-for="(tip, i) in tips" :key="i">

                <v-banner-text>
                    {{ $t(tip.title) }}
                </v-banner-text>


            </v-banner>
        </v-card-text>
    </v-card>


</template>

<script>

import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';

export default defineComponent({
    name: 'ImportantTips',

    data() {
        return {
            tips: [
                { title: 'postiveTip1', icon: "mdi-thumb-up", color: "green" },
                { title: 'postiveTip2', icon: "mdi-thumb-up", color: "green" },
                { title: 'postiveTip3', icon: "mdi-thumb-up", color: "green" },
                { title: 'postiveTip4', icon: "mdi-thumb-up", color: "green" },
                { title: 'postiveTip5', icon: "mdi-thumb-up", color: "green" },
                { title: 'postiveTip6', icon: "mdi-thumb-up", color: "green" },
                { title: 'postiveTip7', icon: "mdi-thumb-up", color: "green" },
                { title: 'negativeTip1', icon: "mdi-thumb-down", color: "red" },
                { title: 'negativeTip2', icon: "mdi-thumb-down", color: "red" },
                { title: 'negativeTip3', icon: "mdi-thumb-down", color: "red" },
                { title: 'negativeTip4', icon: "mdi-thumb-down", color: "red" },
                { title: 'negativeTip5', icon: "mdi-thumb-down", color: "red" },

            ]
        }
    },
    components: {
        PageTitleComponent
    },
});
</script>