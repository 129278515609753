<template>
  <PageTitleComponent :pageTitle="$t('Daily Usage')"></PageTitleComponent>


  <v-card class="mx-3 my-3" variant="elevated">

<v-card-text class="text-justify">
    {{ $t('DailyUsageTopParagraph') }}
</v-card-text>
</v-card>


<v-card class="my-2" variant="elevated">
        <v-card-text>
            <router-link  :to="dailyuse.route" class="router-link" v-for="(dailyuse, i) in dailyuses" :key="i">
            <v-banner class="elevation-5 mb-3" bg-color="expansionPanelTitleColor" lines="one" :icon="dailyuse.icon" color="listIconColor" >            
                <v-banner-text>
                    {{ $t(dailyuse.title) }}
                </v-banner-text>
                <template v-slot:actions>
                    <v-btn size="large">
                    <v-icon icon="mdi-arrow-right-bold"></v-icon>
                </v-btn>
                </template>
            </v-banner>
              </router-link>
        </v-card-text>
    </v-card>


</template>

<script>
import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';

export default defineComponent({
  name: 'DailyUsage',
  data() {
    return {
      dailyuses: [
        { title: 'Recharging Your Device', icon: "mdi-battery-charging-high", route: "/rechargingDevice" },
        { title: 'Sleeping', icon: "mdi-bed-clock", route: "/healthcareProvider" },
        { title: 'Showering/Bathing', icon: "mdi-shower-head", route: "/healthcareProvider" },
        { title: 'Portable Usage', icon: "mdi-human", route: "/healthcareProvider" },
        { title: 'Cleaning Your Device', icon: "mdi-spray-bottle", route: "/healthcareProvider" }
      ]
    }
  },
  components: {
    PageTitleComponent
  },
}
)
</script>