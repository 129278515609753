<template>

    <PageTitleComponent :pageTitle="$t('Healthcare Provider')"></PageTitleComponent>


    <v-card class="mx-3 my-3" variant="elevated">
        
        <v-card-text class="text-justify">
            {{ $t('HealtCareProviderTopParagraph') }}
        </v-card-text>
    </v-card>
    <v-card class="my-2" variant="elevated">
        <v-card-text>
    <v-banner class="my-2 rounded-lg" color="listIconColor" :icon="whenTo.icon" lines="two" bg-color="expansionPanelTitleColor"
    v-for="(whenTo, i) in whenTos" :key="i">
        <v-banner-text>
            {{ $t(whenTo.title) }}
        </v-banner-text>

      
    </v-banner>
</v-card-text>
    </v-card>
   

</template>

<script>

import { defineComponent } from 'vue';
import PageTitleComponent from '../components/PageTitleComponent.vue';

export default defineComponent({
    name: 'HealthcareProvider',

    data() {
        return {
            whenTos: [
                { title: 'WhentoCall1', icon: "mdi-scent" },
                { title: 'WhentoCall2', icon: "mdi-dots-hexagon" },
                { title: 'WhentoCall3', icon: "mdi-account-injury" },
                { title: 'WhentoCall4', icon: "mdi-water-circle" },
                { title: 'WhentoCall5', icon: "mdi-alert-remove" },
                { title: 'WhentoCall6', icon: "mdi-hand-heart" },
                { title: 'WhentoCall7', icon: "mdi-timer-alert" },
                { title: 'WhentoCall8', icon: "mdi-medical-bag" }
            ]
        }
    },
    components: {
        PageTitleComponent
    },
});
</script>